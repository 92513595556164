import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { getSessionDataHttp, SessionData } from 'network/session'

export interface SessionState {
  data: SessionData | null
  error?: SerializedError
}

export const getSessionData = createAsyncThunk('session/get', async () => {
  const sessionToken = Cookies.get('ws_session_auth_hash')
  if (!sessionToken) {
    return null
  }

  return await getSessionDataHttp(decodeURIComponent(sessionToken))
})

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {} as SessionState,
  reducers: {
    clearSessionData: () => ({ data: null }),
  },
  extraReducers(builder) {
    builder.addCase(getSessionData.fulfilled, (state, action) => {
      state.data = action.payload?.data ?? null
      state.error =
        (action?.payload?.status || 200) >= 400
          ? { name: 'Error', message: action.payload?.statusText }
          : undefined
    })
    builder.addCase(getSessionData.rejected, (state, action) => {
      state.error = action.error
      state.data = {} as SessionData
    })
  },
})

export const actions = sessionSlice.actions
