import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const WS_API = process.env.GATSBY_WINDSCRIBE_API_BASE_URL ?? ''

export interface Pop {
  status: number
  name: string
  usage: number
  link_speed: string
}

export interface Location {
  name: string
  country_code: string
  p2p: number
  premium_only: number
  pops_enabled: number
  pops: Pop[]
}

export interface Locations {
  [key: string]: Location
}

interface Totals {
  connections: number
  usage: string
  users: string
}

export interface WebsiteStatusResponse {
  data: {
    totals: Totals
    locations: Locations
  }
}

export interface TransparencyResponse {
  data: {
    stats_dmca: Record<string, string>[]
    stats_data: Record<string, string>[]
    success: boolean
  }
}

export interface PlatformInfo {
  integration: string
  type: string
  platform: string
  release_version: string
  release_build: number
  release_date: string
  release_full_version: string
  beta_full_version: string
  guinea_pig_full_version: string
}

interface Desktop {
  osx: PlatformInfo[]
  windows: PlatformInfo[]
  linux_deb: PlatformInfo[]
  linux_rpm: PlatformInfo[]
  linux: PlatformInfo[]
  linux_deb_x64: PlatformInfo[]
  linux_deb_x86: PlatformInfo[]
  linux_deb_arm: PlatformInfo[]
  linux_rpm_x64: PlatformInfo[]
  linux_rpm_x86: PlatformInfo[]
  windows_arm64: PlatformInfo[]
  linux_deb_arm64: PlatformInfo[]
}

interface Extension {
  chrome: PlatformInfo[]
  firefox: PlatformInfo[]
  opera: PlatformInfo[]
  edge: PlatformInfo[]
}

interface Mobile {
  ios: PlatformInfo[]
  android: PlatformInfo[]
  android_direct_phone: PlatformInfo[]
  android_direct_tv: PlatformInfo[]
}

interface Tv {
  firetv: PlatformInfo[]
  'android-tv': PlatformInfo[]
  appletv: PlatformInfo[]
}

interface Data {
  desktop: Desktop
  extension: Extension
  mobile: Mobile
  tv: Tv
}

interface Metadata {
  serviceRequestId: string
  hostName: string
  duration: string
  logStatus: string | null
  md5: string
}

interface ChangeLogSummaryResponse {
  data: Data
  metadata: Metadata
}

export interface DataItem {
  id: number
  integration: string
  type: string
  platform: string
  version: string
  build: number
  beta: number
  url: string
  is_supported: number
  upgradable: number
  force_upgrade: number
  min_version: string
  sha256: string
  changelog: string
  release_date: string
}

interface ChangeLogPlatformResponse {
  data: DataItem[]
  metadata: Metadata
}

// eslint-disable-next-line
export enum SupportCategory {
  NOT_SELECTED = 0,
  ACCOUNT_ISSUE = 1,
  TECHNICAL_PROBLEM = 2,
  SALES_QUESTION = 3,
  FEEDBACK = 4,
}

export interface SubmitSupportTicketRequestBody {
  support_name?: string
  support_email: string
  support_subject: string
  support_message: string
  support_category: SupportCategory
  issue_metadata?: Record<string, unknown>
  session_auth_hash?: string
}

export interface SubmitSupportTicketResponse {
  data: {
    ticket_id: number
  }
  errorMessage: string
  errorDescription: string
}

export const wsApi = createApi({
  reducerPath: 'wsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: WS_API,
  }),
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    transparencyReport: builder.query<TransparencyResponse, string>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: `/TransparencyStats`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer 1234',
        },
      }),
    }),
    sendSupportTicket: builder.mutation<
      SubmitSupportTicketResponse,
      SubmitSupportTicketRequestBody
    >({
      query: body => ({
        url: '/SupportTicket',
        method: 'POST',
        body,
        headers: {
          Authorization: 'Bearer 1234',
        },
      }),
    }),
    statusReport: builder.query<WebsiteStatusResponse, string>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: '/WebsiteStatus',
        method: 'GET',
        headers: {
          Authorization: 'Bearer 1234',
        },
      }),
    }),
    changelogSummary: builder.query<ChangeLogSummaryResponse, string>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: '/ChangeLogs/summary',
        method: 'GET',
        headers: {
          Authorization: 'Bearer 1234',
        },
      }),
    }),
    changelogs: builder.query<ChangeLogPlatformResponse, { platform: string }>({
      keepUnusedDataFor: 0,
      query: ({ platform }: { platform: string }) => ({
        url: `/ChangeLogs?platform=${platform}`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer 1234',
        },
      }),
    }),
  }),
})
