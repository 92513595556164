/**
 * In production, we have an alternate domain, windscribe.net, which is used to get around
 * domain blocks of windscribe.com.
 *
 * This slightly hacky function rewrites API base URL (such as Typesense and the Windscribe API)
 * TLDs to also be `.net` in order to preserve the domain-block-bypassing behaviour the `.net`
 * domain is intended for.
 */
export default function transformProductionApiUrlTld(apiUrl: string): string {
  if (isDotNetDomain()) {
    return apiUrl.replace(/\.com$/, '.net')
  } else {
    return apiUrl
  }
}

export function isDotNetDomain(): boolean {
  if (typeof window === 'undefined') {
    return false
  }

  return window.location.hostname.endsWith('.net')
}
