import md5 from 'md5'
import { ApiResponse } from './api-response'
import transformProductionApiUrlTld from 'utils/transformProductionApiUrlTld'
import Cookies from 'js-cookie'

const notReallyASecret = '952b4412f002315aa50751032fcaab03'

// taken from sample response in
// https://gitlab.int.windscribe.com/ws/frontend/web/website-kb-cms/-/issues/40
export interface SessionData {
  username: string
  user_id: string
  traffic_used: number
  traffic_max: number
  status: number
  email: string
  email_status: number
  billing_plan_id: number
  is_premium: number
  rebill: number
  premium_expiry_date: string
  reg_date: number
  last_reset: string
  proxy_optout: number | null
  twofa_status: number
  dns: number
  secure_links_secret: string
  aff_rate: string
  scribeforce: boolean
  loc_rev: number
  loc_hash: string
}

export async function getSessionDataHttp(
  sessionToken: string,
): Promise<ApiResponse<SessionData> | null> {
  const time = Math.floor(Date.now() / 1000)

  const body = {
    time,
    client_auth_hash: md5(`${notReallyASecret}${time}`),
    session_auth_hash: sessionToken,
  }

  const url =
    `${transformProductionApiUrlTld(process.env.GATSBY_WINDSCRIBE_API_BASE_URL ?? '')}/Session?` +
    new URLSearchParams({
      time: String(body.time),
      client_auth_hash: body.client_auth_hash,
      session_auth_hash: body.session_auth_hash,
    })

  const untypedResponse = (await fetch(url, {
    method: `GET`,
    headers: {
      'Content-Type': `application/json`,
    },
  })) as ApiResponse<SessionData>

  const responseBody = await untypedResponse.json()

  if (!untypedResponse.ok) {
    Cookies.remove('ws_session_auth_hash')
  }

  return {
    ...untypedResponse,
    // for some reason, just spreading `untypedResponse` doesn't work, so we need to
    // explicitly add these properties to detect errors
    status: untypedResponse.status,
    statusText: untypedResponse.statusText,
    data: responseBody.data,
  }
}
