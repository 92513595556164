import { Provider as ReduxProvider } from 'react-redux'
import './global.css'
import { GatsbyBrowser, Script } from 'gatsby'
import { QueryParamProvider } from 'use-query-params'
import React, { ReactNode } from 'react'
import { history } from 'utils/history'
import { Helmet } from 'react-helmet'
import store from 'store'
import Cookies from 'js-cookie'
// import 'garry.js'

export const wrapRootElement = ({ element }: { element: ReactNode }): ReactNode => {
  return (
    <ReduxProvider store={store}>
      <Script src={`${process.env.GATSBY_GARRY_BASE_URL}/client-gpt/garry-version-selector.js`} />
      {process.env.GATSBY_ENVIRONMENT !== 'production' && (
        <Helmet>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
      )}
      {element}
    </ReduxProvider>
  )
}

/**
 * Prevents the scroll from going back to the top when chanigng the platform
 */
export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  prevRouterProps,
  routerProps,
}) => {
  return prevRouterProps?.location?.pathname !== routerProps.location?.pathname
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return (
    <QueryParamProvider history={history} location={props.location}>
      {element}
    </QueryParamProvider>
  )
}

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  document.body.classList.add('hydrated')
}

/**
 * Whenever the route update we check if our campaign params are set. If they are we create cookies for them
 */
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  const urlParams = new URLSearchParams(location.search)

  const cookieRef = Cookies.get('ref')
  const cookieCpid = Cookies.get('cpid')
  const cookiePcpid = Cookies.get('pcpid')
  const cookieFriend = Cookies.get('friend')

  const cpidParam = urlParams.get('cpid')
  const pcpidParam = urlParams.get('pcpid')
  const friendParam = urlParams.get('friend')
  const refParam = urlParams.get('ref')

  if (!cookiePcpid && pcpidParam) {
    Cookies.set('pcpid', pcpidParam, { expires: 86400 })
  }

  if (!cookieCpid && cpidParam) {
    Cookies.set('cpid', cpidParam, { expires: 86400 * 30 })
  }

  if (!cookieFriend && friendParam) {
    Cookies.set('friend', friendParam, { expires: 86400 * 30 })
  }

  if (!cookieRef && refParam) {
    Cookies.set('ref', refParam, { expires: 86400 * 30 })
  }
}
